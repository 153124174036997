<template>
  <div>
      <!-- <SingleChatContainer :roomId="roomId"></SingleChatContainer> -->
      <FirebaseGrid></FirebaseGrid>
  </div>
</template>

<script>
import SingleChatContainer from '@/chat/SingleChatContainer.vue'
import FirebaseGrid from '@/components/FirebaseGrid.vue'

export default {
  components: {
    SingleChatContainer,
    FirebaseGrid
  },

  props: {
    roomId: {
      type: String,
      default: null
    }
  },

  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    
  },
  beforeMount() {
    
  }
}
</script>
