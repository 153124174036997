<template>
  <div>
    <slot name="filter"></slot>

    <vs-table
      :sst="true"
      @change-page="handleChangePage"
      @sort="handleSort"
      @selected="$emit('selected', $event)"
      v-model="itemSelected"
      pagination
      :max-items="maxItems"
      :data="model.data"
      :total="totalItems"
      :no-data-text="noDataText"
      current-sort-type="asc"
      :class="tableStyle"
      :max="paginationMaxItems"
    >
      <template slot="header">
        <div class="vs-row w-full pb-2 gap-2 flex items-end">
          <div class="flex-grow">
            <slot name="filterZone">
            </slot>
          </div>
          
          <div v-if="!hide_searchbar" class="flex w-full sm:w-auto gap-1">
            <vs-input icon="search" v-model="searchInput" class="searchbar" @input="handleSearch(searchInput)">
            </vs-input>
            <button-alternates
              v-if="showExport"
              :label="$t('exportar')"
              buttonClass="px-4"
              :alternatives="[
                {
                  icon: 'picture_as_pdf',
                  label: 'PDF',
                  color: 'red',
                  click: () => {exportGrid('pdf')}
                },
                {
                  icon: 'border_all',
                  label: 'xls',
                  color: 'success',
                  click: () => {exportGrid('xls')}
                }
              ]"
              @click-main="exportGrid('pdf')"
            />
            <div class="w-full mt-2 text-right" v-if="showTotalText"><b>{{showTotalText}}:</b> {{ totalItems }}</div>
          </div>
        </div>
      </template>

      <template slot="thead" v-if="theadShow">
        <slot name="gridThead" v-bind:data="columns">
          <vs-th
            v-for="(column, key) in columns"
            :key="key"
            :sort-key="key"
            sort-status="asc"
            >{{ $t(column) }}</vs-th
          >
        </slot>
        <slot name="gridActions">
          <vs-th sort-key="actions" v-if="!hide_actions">{{
            $t('Actions')
          }}</vs-th>
        </slot>
      </template>

      <template slot-scope="{ data }">
        <slot
          name="gridData"
          v-bind:data="data"
          v-bind:destroy="destroy"
          v-bind:edit="edit"
          v-bind:fetchGridData="fetchGridData"
        >
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              :data="data[indextr].id"
              v-for="(column, key) in columns"
              :key="key"
              >
                <div :class="getColumnCss(data[indextr], key)">
                  {{ getColumnData(data[indextr], key) }}
                </div>
              </vs-td
            >
            <vs-td v-if="!hide_actions">
              <feather-icon
                v-permission="`${route_name}.edit`"
                icon="EditIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                v-if="!hide_edit"
                @click="edit(data[indextr].id)"
              />
              <feather-icon
                icon="EyeIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                v-if="!hide_show"
              />
              <feather-icon
                v-permission="`${route_name}.delete`"
                icon="Trash2Icon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
                v-if="!hide_delete"
                @click="destroy(data[indextr])"
              />
            </vs-td>
          </vs-tr>
        </slot>
      </template>
    </vs-table>
  </div>
</template>

<script>
import ReportService from '@/services/api/ReportService'
import ButtonAlternates from './ButtonAlternates.vue'
import FileDownload from 'js-file-download'
import { mimeType } from '@/util/Util'

export default {
  props: {
    hide_searchbar: {
      type: Boolean,
      default: false
    },
    hide_actions: {
      type: Boolean,
      default: false,
    },
    hide_edit: {
      type: Boolean,
      default: false,
    },
    hide_show: {
      type: Boolean,
      default: true,
    },
    hide_delete: {
      type: Boolean,
      default: false,
    },
    route_name: {
      type: String,
      default: null,
    },
    service: {
      type: Object,
      default: null,
    },
    route_grid_path: {
      type: String,
      default: '',
    },
    route_delete_path: {
      type: String,
      default: '',
    },
    order_column: {
      type: String,
      default: 'id',
    },
    direction: {
      type: String,
      default: 'asc',
    },
    delegate: {
      type: Object,
      default: null,
    },
    theadShow: {
      type: Boolean,
      default: true
    },
    showLoading: {
      type: Boolean,
      default: true
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    noDataText: {
      type: String,
      default: function () {
        return this.$t('nenhum-registro-encontrado')
      }
    },
    paginationMaxItems: {
      type: Number,
      default: 9
    },
    perPage: {
      type: Number,
      default: 10
    },
    column_formats: {
      type: Object,
      default: () => ({})
    },
    css_formats: {
      type: Object,
      default: () => ({})
    },
    showTotalText: {
      type: String,
      default: null
    },
    totalFormat: {
      type: Function,
      default: (total) => `${total}`
    },
    fetchOnMounted: {
      type: Boolean,
      default: true
    },
    showExport: {
      type: Boolean,
      default: false
    },
    exportFilename: {
      type: String,
      default: 'export'
    }
  },
  components: {
    ButtonAlternates
  },
  data() {
    return {
      model: {
        current_page: 0,
        data: [],
        last_page: 0,
        next_page_url: '',
        prev_page_url: '',
        to: 10,
        total: 0,
      },
      columns: {},
      filterMode: false,
      filter: {
        items: [''],
      },
      custom_filters: [],
      searchInput: '',
      dataToDestroy: null,
      itemSelected: null,
      exportService: null
    }
  },
  computed: {
    totalItems() {
      return this.model.total
    },
    maxItems() {
      return this.perPage
      // return this.hidePagination ? this.model.data.length : this.per_page
    },
    hasFilterZone() {
      return !!this.$slots['filterZone'] || !!this.$scopedSlots['filterZone']
    },
    searchbarDivWidth () {
      return this.hasFilterZone ? 'w-full md:w-1/2' : 'w-full'
    },
    hasFilters() {
      const hasAnyFilter = (this.filter.items.length !== 0 || this.custom_filters.length !== 0)

      return hasAnyFilter
    },
    tableStyle() {
      return this.hidePagination ? 'hidePagination' : ''
    }
  },
  watch: {
    itemSelected() {
      if (!this.isEmpty(this.delegate) && this.delegate.itemSelected) {
        const itemSelectedFunction = this.delegate.itemSelected()
        itemSelectedFunction(this.itemSelected)
      }
    },
  },
  methods: {
    getParams() {
      return {
        page: this.model.current_page,
        order_column: this.order_column,
        direction: this.direction,
        per_page: this.perPage,
        filters: this.filter,
        custom_filters: this.custom_filters,
      }
    },
    fetchGridData(reset_page = false, params = {}) {
      return new Promise((resolve, reject) => {
        if (this.service) {
          if (this.showLoading) {
            this.$vs.loading()
          }

          if (this.filter && !this.isEmpty(this.searchInput)) {
            this.filter.items[0] = this.searchInput
            this.model.current_page = 1
          } else {
            this.filter.items[0] = ''
          }

          this.service
            .grid(this.route_grid_path, this.getParams())
            .then(
              (response) => {
                this.$vs.loading.close()
                this.$set(this.$data, 'model', response.model)
                this.$set(this.$data, 'columns', response.columns)
                this.$emit('grid-UPDATED', response)
                resolve()
              },
              (error) => {
                this.$vs.loading.close()
                reject()
              }
            )
        } else {
          reject()
        }
      })
    },
    exportGrid(type) {
      if (this.exportService) {
        this.$vs.loading()
        this.exportService.exportGrid(this.route_grid_path, type, this.getParams()).then(
          (data) => {
            this.$vs.loading.close()
            FileDownload(data, `${this.exportFilename}.${type}`, mimeType(type))
          }
        )
      }
    },
    getColumnCss(rowData, column) {
      const columnData = this._.get(rowData, column)
      if (column in this.css_formats && (this.css_formats[column] instanceof Function)) {
        return this.css_formats[column](columnData)
      }

      return ''
    },
    getColumnData(rowData, column) {
      const columnData = this._.get(rowData, column)
      if (column in this.column_formats && (this.column_formats[column] instanceof Function)) {
        return this.column_formats[column](columnData)
      }
      
      return columnData
      
    },
    edit(id) {
      this.$router.push(`${this.route_name}/${id}/edit`)
    },
    destroy(data) {
      this.dataToDestroy = data
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: this.acceptDestroy,
      })
    },
    acceptDestroy() {
      if (this.delegate && this.delegate.destroy) {
        const destroyFunction = this.delegate.destroy()
        destroyFunction(this.dataToDestroy)
      } else {
        this.$vs.loading()
        this.service.destroy(this.dataToDestroy.id).then(
          (response) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Successo!',
              text: this.$t('operacao-realizada-com-sucesso'),
              color: 'success',
              iconPack: 'feather',
              position: 'top-center',
              icon: 'icon-check-circle',
            })

            this.fetchGridData(this.current_page)
          },
          (error) => {
            this.$vs.loading.close()
          }
        )
      }
    },
    handleSearch(searching) {
      this.searchInput = searching
      if (searching === '') {
        this.filter.items = []
      }
      // Debounce fetch
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.fetchGridData()
      }, 1000)
    },
    handleFilter(value, filter) {
      this.timeout = setTimeout(() => {
        this.custom_filters[filter] = value
        this.fetchGridData()
      }, 1000)
    },
    handleChangePage(page) {
      this.model.current_page = page
      return this.fetchGridData(page)
    },
    handleSort(key, direction) {
      this.order_column = !this.isEmpty(key) ? key.toLowerCase() : 'id' //default??
      if (direction !== null) {
        this.direction = direction
      } else {
        this.direction = 'asc'
      }

      if (direction === null) {
        this.order_column = 'id'
      }

      this.fetchGridData(true)
    },
    //Updates custom filters from param.
    fillCustomFilters(customFilters) {
      customFilters.forEach(filter => {
        this.custom_filters[filter.key] = filter.value
      })
    },
    //Replaces custom filters and items with params and fetch data.
    flashFilters(items, custom) {
      this.dlog('flashing filters, items> ', items, ' custom> ', custom)
      this.custom_filters = custom
      this.filter.items = items
      return this.handleChangePage(1)
    },
    resetFilters() {
      this.filter.items = []
      this.custom_filters = []
    },
    applySimpleContent(content) {
      this.model.data = content
    },
    dlog(...things) {
      this.devLog(`grid:${this.route_name || this.route_grid_path}`, ...things)
    }
  },
  mounted() {
    if (this.fetchOnMounted) {
      this.fetchGridData()
    }
    if (this.showExport) {
      this.exportService = ReportService.build(this.$vs)
    }
    this.$emit('ready', this)
  },
}
</script>

<style>

  .searchbar {
    width: 100% !important;
  }

  .searchbar input {
    height: 38px;
  }
  .searchbar .vs-input--icon {
    top: 11px;
    left: 8px;
    border: none;
  }
  .searchbar > .vs-con-input > input {
    padding-left: 2rem !important; 
  }

  /* .hidePagination {
    background-color: #ff99ff;
  } */

  .hidePagination div.con-vs-pagination {
    display: none;
  }
</style>
